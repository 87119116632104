
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'districtTraineeSummary',
  components: {},
  data() {
    return {
      load: false,
      loading: false,

      componentKey: 0,
    };
  },
  async created() {
    // await this.districtTrainee();
  },
  methods: {},
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
